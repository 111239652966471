<template>
  <div class="area py-16 flex justify-between flex-wrap ">
    <div class="max-w-lg mr-4">
      <h1 class="text-4xl">Privacy Policy</h1>

      <p class="mt-2" data-children-count="0">
        Gorm Labenz built the Triptalk app as a Freemium app. This SERVICE is
        provided by Gorm Labenz at no cost and is intended for use as is.
      </p>
      <p class="mt-2" data-children-count="0">
        This page is used to inform visitors regarding my policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use my Service.
      </p>
      <p class="mt-2" data-children-count="0">
        If you choose to use my Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that I collect is used for providing and improving the Service. I will
        not use or share your information with anyone except as described in
        this Privacy Policy.
      </p>
      <p class="mt-2" data-children-count="0">
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which is accessible at Triptalk unless otherwise
        defined in this Privacy Policy.
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Information Collection and Use</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        For a better experience, while using our Service, I may require you to
        provide us with certain personally identifiable information, including
        but not limited to Gorm Labenz, info@triptalk.space, 10717 Berlin. The
        information that I request will be retained on your device and is not
        collected by me in any way.
      </p>
      <div data-children-count="0">
        <p>
          The app does use third party services that may collect information
          used to identify you.
        </p>
        <p>
          Link to privacy policy of third party service providers used by the
          app
        </p>
        <ul>
          <li>
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              >Google Play Services</a
            >
          </li>
          <!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!---->
        </ul>
      </div>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Log Data</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        I want to inform you that whenever you use my Service, in a case of an
        error in the app I collect data and information (through third party
        products) on your phone called Log Data. This Log Data may include
        information such as your device Internet Protocol (“IP”) address, device
        name, operating system version, the configuration of the app when
        utilizing my Service, the time and date of your use of the Service, and
        other statistics.
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Cookies</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
      </p>
      <p class="mt-2" data-children-count="0">
        This Service does not use these “cookies” explicitly. However, the app
        may use third party code and libraries that use “cookies” to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Service Providers</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        I may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <ul data-children-count="0">
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p class="mt-2" data-children-count="0">
        I want to inform users of this Service that these third parties have
        access to your Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Security</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        I value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and I cannot guarantee
        its absolute security.
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Links to Other Sites</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by me. Therefore, I strongly advise you
        to review the Privacy Policy of these websites. I have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Children’s Privacy</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        These Services do not address anyone under the age of 13. I do not
        knowingly collect personally identifiable information from children
        under 13 years of age. In the case I discover that a child under 13 has
        provided me with personal information, I immediately delete this from
        our servers. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact me so
        that I will be able to do necessary actions.
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Mapbox</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        Mapbox is a third-party provider that we use to render the maps in the
        iOS and Android apps. When you enable Mapbox tracking, your data is
        shared with your data to Mapbox anonymously. Triptalk has no access to
        it. It may be that the data usage increases slightly. increase.
        Otherwise, there are no other effects from the app. The Mapbox app uses
        this data to improve its software, which will ultimately improve will
        ultimately improve Triptalk as well. More information about what data is
        used for what, you can find on the Mapbox website.
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Changes to This Privacy Policy</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        I may update our Privacy Policy from time to time. Thus, you are advised
        to review this page periodically for any changes. I will notify you of
        any changes by posting the new Privacy Policy on this page.
      </p>
      <p class="mt-2" data-children-count="0">
        This policy is effective as of 2021-03-08
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Contact Us</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        If you have any questions or suggestions about my Privacy Policy, do not
        hesitate to contact me at info@triptalk.space.
      </p>

      <p class="mt-2" data-children-count="0">
        This privacy policy page was created at
        <a
          href="https://privacypolicytemplate.net"
          target="_blank"
          rel="noopener noreferrer"
          >privacypolicytemplate.net </a
        >and modified/generated by
        <a
          href="https://app-privacy-policy-generator.nisrulz.com/"
          target="_blank"
          rel="noopener noreferrer"
          >App Privacy Policy Generator</a
        >
      </p>
    </div>
    <div class="max-w-lg">
      <h1 class="text-4xl">Terms &amp; Conditions</h1>
      <p class="mt-2" data-children-count="0">
        By downloading or using the app, these terms will automatically apply to
        you – you should make sure therefore that you read them carefully before
        using the app. You’re not allowed to copy, or modify the app, any part
        of the app, or our trademarks in any way. You’re not allowed to attempt
        to extract the source code of the app, and you also shouldn’t try to
        translate the app into other languages, or make derivative versions. The
        app itself, and all the trade marks, copyright, database rights and
        other intellectual property rights related to it, still belong to Gorm
        Labenz.
      </p>
      <p class="mt-2" data-children-count="0">
        Gorm Labenz is committed to ensuring that the app is as useful and
        efficient as possible. For that reason, we reserve the right to make
        changes to the app or to charge for its services, at any time and for
        any reason. We will never charge you for the app or its services without
        making it very clear to you exactly what you’re paying for.
      </p>
      <p class="mt-2" data-children-count="0">
        The Triptalk app stores and processes personal data that you have
        provided to us, in order to provide my Service. It’s your responsibility
        to keep your phone and access to the app secure. We therefore recommend
        that you do not jailbreak or root your phone, which is the process of
        removing software restrictions and limitations imposed by the official
        operating system of your device. It could make your phone vulnerable to
        malware/viruses/malicious programs, compromise your phone’s security
        features and it could mean that the Triptalk app won’t work properly or
        at all.
      </p>
      <div data-children-count="0">
        <p>
          The app does use third party services that declare their own Terms and
          Conditions.
        </p>
        <p>
          Link to Terms and Conditions of third party service providers used by
          the app
        </p>
        <ul>
          <li>
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
              >Google Play Services</a
            >
          </li>
          <!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!---->
        </ul>
      </div>
      <p class="mt-2" data-children-count="0">
        You should be aware that there are certain things that Gorm Labenz will
        not take responsibility for. Certain functions of the app will require
        the app to have an active internet connection. The connection can be
        Wi-Fi, or provided by your mobile network provider, but Gorm Labenz
        cannot take responsibility for the app not working at full functionality
        if you don’t have access to Wi-Fi, and you don’t have any of your data
        allowance left.
      </p>
      <p class="mt-2" data-children-count="0"></p>
      <p class="mt-2" data-children-count="0">
        If you’re using the app outside of an area with Wi-Fi, you should
        remember that your terms of the agreement with your mobile network
        provider will still apply. As a result, you may be charged by your
        mobile provider for the cost of data for the duration of the connection
        while accessing the app, or other third party charges. In using the app,
        you’re accepting responsibility for any such charges, including roaming
        data charges if you use the app outside of your home territory (i.e.
        region or country) without turning off data roaming. If you are not the
        bill payer for the device on which you’re using the app, please be aware
        that we assume that you have received permission from the bill payer for
        using the app.
      </p>
      <p class="mt-2" data-children-count="0">
        Along the same lines, Gorm Labenz cannot always take responsibility for
        the way you use the app i.e. You need to make sure that your device
        stays charged – if it runs out of battery and you can’t turn it on to
        avail the Service, Gorm Labenz cannot accept responsibility.
      </p>
      <p class="mt-2" data-children-count="0">
        With respect to Gorm Labenz’s responsibility for your use of the app,
        when you’re using the app, it’s important to bear in mind that although
        we endeavour to ensure that it is updated and correct at all times, we
        do rely on third parties to provide information to us so that we can
        make it available to you. Gorm Labenz accepts no liability for any loss,
        direct or indirect, you experience as a result of relying wholly on this
        functionality of the app.
      </p>
      <p class="mt-2" data-children-count="0">
        At some point, we may wish to update the app. The app is currently
        available on Android – the requirements for system(and for any
        additional systems we decide to extend the availability of the app to)
        may change, and you’ll need to download the updates if you want to keep
        using the app. Gorm Labenz does not promise that it will always update
        the app so that it is relevant to you and/or works with the Android
        version that you have installed on your device. However, you promise to
        always accept updates to the application when offered to you, We may
        also wish to stop providing the app, and may terminate use of it at any
        time without giving notice of termination to you. Unless we tell you
        otherwise, upon any termination, (a) the rights and licenses granted to
        you in these terms will end; (b) you must stop using the app, and (if
        needed) delete it from your device.
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Mapbox</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        Mapbox is a third-party provider that we use to render the maps in the
        iOS and Android apps. When you enable Mapbox tracking, your data is
        shared with your data to Mapbox anonymously. Triptalk has no access to
        it. It may be that the data usage increases slightly. increase.
        Otherwise, there are no other effects from the app. The Mapbox app uses
        this data to improve its software, which will ultimately improve will
        ultimately improve Triptalk as well. More information about what data is
        used for what, you can find on the Mapbox website.
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 "
          >Changes to This Terms and Conditions</strong
        >
      </p>
      <p class="mt-2" data-children-count="0">
        I may update our Terms and Conditions from time to time. Thus, you are
        advised to review this page periodically for any changes. I will notify
        you of any changes by posting the new Terms and Conditions on this page.
      </p>
      <p class="mt-2" data-children-count="0">
        These terms and conditions are effective as of 2021-03-08
      </p>
      <p class="mt-2" data-children-count="0">
        <strong class="text-2xl mt-8 ">Contact Us</strong>
      </p>
      <p class="mt-2" data-children-count="0">
        If you have any questions or suggestions about my Terms and Conditions,
        do not hesitate to contact me at info@triptalk.space.
      </p>
    </div>
    <div class="max-w-lg ">
      <h2 class="text-4xl my-16">
        End-User License Agreement (EULA) of
        <span class="app_name">Triptalk</span>
      </h2>

      <p class="mt-2">
        This End-User License Agreement ("EULA") is a legal agreement between
        you and <span class="company_name">Labenz</span>. Our EULA was created
        by <a href="https://www.eulatemplate.com">EULA Template</a> for
        <span class="app_name">Triptalk</span>.
      </p>

      <p class="mt-2">
        This EULA agreement governs your acquisition and use of our
        <span class="app_name">Triptalk</span> software ("Software") directly
        from <span class="company_name">Labenz</span> or indirectly through a
        <span class="company_name">Labenz</span> authorized reseller or
        distributor (a "Reseller"). Our Privacy Policy was created by
        <a href="https://www.generateprivacypolicy.com/"
          >the Privacy Policy Generator</a
        >.
      </p>

      <p class="mt-2">
        Please read this EULA agreement carefully before completing the
        installation process and using the
        <span class="app_name">Triptalk</span> software. It provides a license
        to use the <span class="app_name">Triptalk</span> software and contains
        warranty information and liability disclaimers.
      </p>

      <p class="mt-2">
        If you register for a free trial of the
        <span class="app_name">Triptalk</span> software, this EULA agreement
        will also govern that trial. By clicking "accept" or installing and/or
        using the <span class="app_name">Triptalk</span> software, you are
        confirming your acceptance of the Software and agreeing to become bound
        by the terms of this EULA agreement.
      </p>

      <p class="mt-2">
        If you are entering into this EULA agreement on behalf of a company or
        other legal entity, you represent that you have the authority to bind
        such entity and its affiliates to these terms and conditions. If you do
        not have such authority or if you do not agree with the terms and
        conditions of this EULA agreement, do not install or use the Software,
        and you must not accept this EULA agreement.
      </p>

      <p class="mt-2">
        This EULA agreement shall apply only to the Software supplied by
        <span class="company_name">Labenz</span> herewith regardless of whether
        other software is referred to or described herein. The terms also apply
        to any <span class="company_name">Labenz</span> updates, supplements,
        Internet-based services, and support services for the Software, unless
        other terms accompany those items on delivery. If so, those terms apply.
      </p>

      <h3 class="text-2xl mt-8 ">License Grant</h3>

      <p class="mt-2">
        <span class="company_name">Labenz</span> hereby grants you a personal,
        non-transferable, non-exclusive licence to use the
        <span class="app_name">Triptalk</span> software on your devices in
        accordance with the terms of this EULA agreement.
      </p>

      <p class="mt-2">
        You are permitted to load the
        <span class="app_name">Triptalk</span> software (for example a PC,
        laptop, mobile or tablet) under your control. You are responsible for
        ensuring your device meets the minimum requirements of the
        <span class="app_name">Triptalk</span> software.
      </p>

      <p class="mt-2">You are not permitted to:</p>

      <ul>
        <li>
          Edit, alter, modify, adapt, translate or otherwise change the whole or
          any part of the Software nor permit the whole or any part of the
          Software to be combined with or become incorporated in any other
          software, nor decompile, disassemble or reverse engineer the Software
          or attempt to do any such things
        </li>
        <li>
          Reproduce, copy, distribute, resell or otherwise use the Software for
          any commercial purpose
        </li>
        <li>
          Allow any third party to use the Software on behalf of or for the
          benefit of any third party
        </li>
        <li>
          Use the Software in any way which breaches any applicable local,
          national or international law
        </li>
        <li>
          use the Software for any purpose that
          <span class="company_name">Labenz</span> considers is a breach of this
          EULA agreement
        </li>
      </ul>

      <h3 class="text-2xl mt-8 ">Intellectual Property and Ownership</h3>

      <p class="mt-2">
        <span class="company_name">Labenz</span> shall at all times retain
        ownership of the Software as originally downloaded by you and all
        subsequent downloads of the Software by you. The Software (and the
        copyright, and other intellectual property rights of whatever nature in
        the Software, including any modifications made thereto) are and shall
        remain the property of <span class="company_name">Labenz</span>.
      </p>

      <p class="mt-2">
        <span class="company_name">Labenz</span> reserves the right to grant
        licences to use the Software to third parties.
      </p>

      <h3 class="text-2xl mt-8 ">Termination</h3>

      <p class="mt-2">
        This EULA agreement is effective from the date you first use the
        Software and shall continue until terminated. You may terminate it at
        any time upon written notice to
        <span class="company_name">Labenz</span>.
      </p>

      <p class="mt-2">
        It will also terminate immediately if you fail to comply with any term
        of this EULA agreement. Upon such termination, the licenses granted by
        this EULA agreement will immediately terminate and you agree to stop all
        access and use of the Software. The provisions that by their nature
        continue and survive will survive any termination of this EULA
        agreement.
      </p>

      <h3 class="text-2xl mt-8 ">Governing Law</h3>

      <p class="mt-2">
        This EULA agreement, and any dispute arising out of or in connection
        with this EULA agreement, shall be governed by and construed in
        accordance with the laws of <span class="country">de</span>.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
